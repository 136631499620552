import React, { FC } from 'react';

import { Helmet } from 'react-helmet-async';

import * as O from 'fp-ts/Option';
import { pipe } from 'fp-ts/function';
import config from '@root/config';

interface SeoProps {
  title?: string;
}

const Seo: FC<SeoProps> = ({ title }) => {
  const metaTitle = pipe(
    O.fromNullable(title),
    O.fold(
      () => config.VITE_APP_TITLE,
      t => `${config.VITE_APP_TITLE} - ${t}`,
    ),
  );

  return (
    <Helmet>
      <title>{metaTitle}</title>
    </Helmet>
  );
};

export default Seo;
