import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { HttpStatusCode } from '@core/http';
import { useFetchTask, useSendTask } from '@core/http/hooks';
import { useTokenParam } from '@core/router';
import PasswordsForm from '@modules/auth/components/passwords/PasswordsForm';
import { useAuthContext } from '@modules/auth/context';
import { ActivationToken, PasswordsFormBody } from '@modules/auth/model';
import * as AuthService from '@modules/auth/service';
import Seo from '@shared/modules/seo/Seo';
import { renderHttpRemoteData } from '@shared/utils/render';
import * as TE from 'fp-ts/TaskEither';

const ActivationPage: FC = () => {
  const navigate = useNavigate();

  const token = useTokenParam<ActivationToken>();

  const { updateProfile } = useAuthContext();

  const [detail] = useFetchTask(AuthService.getActivationDetail, token);

  const [loading, send, error] = useSendTask(
    AuthService.activateAccount,
    {
      hideError: true,
    },
    token,
  );

  const handleActivateAccount = ({ password }: PasswordsFormBody) => {
    pipe(
      () => send({ password }),
      TE.chainIOK(profile => () => {
        updateProfile(profile);

        navigate('/', { replace: true });
      }),
    )();
  };

  const errorMessage = pipe(
    error,
    O.map(error =>
      HttpStatusCode.CONFLICT === error.status ? 'Votre compte est déjà activé.' : 'Une erreur technique est survenue.',
    ),
  );

  return renderHttpRemoteData(detail, () => (
    <>
      <Seo title="Activation" />
      <PasswordsForm type="activation" loading={loading} error={errorMessage} onSubmit={handleActivateAccount} />
    </>
  ));
};

export default ActivationPage;
