import { z } from 'zod';

export enum Environment {
  DEV = 'dev',
  STAGING = 'staging',
  PRODUCTION = 'production',
}

export const configSchema = z.object({
  VITE_RELEASE: z.string().nullish(),
  VITE_ENVIRONMENT: z.nativeEnum(Environment),
  VITE_API_PREFIX: z.string().min(1),
  VITE_API_URL: z.string().url(),
  VITE_APP_TITLE: z.string().min(1),
  VITE_SENTRY_DSN: z.string().url().nullish(),

  VITE_LEGAL_NOTICE_URL: z.string().url(),
  VITE_CGU_URL: z.string().url(),
  VITE_CONTACT_PHONE: z.string().min(1),
});

export type Config = z.infer<typeof configSchema>;

const config = import.meta.env as unknown as Config;

export default config;
