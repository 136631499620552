import React, { FC, PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';

import * as Styled from './SimplePage.styles';
import Seo from '@shared/modules/seo/Seo';

interface SimplePageProps {
  title: string;
}

const SimplePage: FC<PropsWithChildren<SimplePageProps>> = ({ title, children }) => {
  const navigate = useNavigate();

  const handleGoBack = () => navigate(-1);

  return (
    <>
      <Seo title={title} />

      <Styled.SimplePageHeader onClick={handleGoBack}>{title}</Styled.SimplePageHeader>

      <Styled.SimplePageContent>{children}</Styled.SimplePageContent>
    </>
  );
};

export default SimplePage;
