import React, { FC } from 'react';

import * as Styled from './LockScreen.styles';

import { Box, Group, MediaQuery, Text } from '@mantine/core';

import logo from '@assets/logos/logo.svg';
import doorImage from '@assets/images/door.svg';

export const LOCK_SCREEN_MAX_WIDTH = 960;

const LockScreen: FC = () => {
  return (
    <MediaQuery smallerThan={LOCK_SCREEN_MAX_WIDTH + 1} styles={{ display: 'none' }}>
      <Box sx={{ height: '100%' }}>
        <Styled.LockScreenContainer>
          <Styled.LockScreenContent>
            <img src={logo} alt="Logo" width={106} height={36} />

            <Text weight="bold" mt={30} size="lg">
              On dirait que vous n’êtes pas sur un appareil mobile
            </Text>

            <Text mt={25} size="sm">
              Pour une meilleure expérience utilisateur, notre outil n‘est pas disponible si votre résolution d’écran
              est supérieur à {LOCK_SCREEN_MAX_WIDTH}px.
            </Text>

            <Text mt={25} weight={600} size="sm">
              Nous vous invitons à vous connecter depuis une tablette ou un smartphone.
            </Text>

            <Text mt={25} size="sm">
              A bientôt !
            </Text>
          </Styled.LockScreenContent>

          <Group position="center" mt={80}>
            <img src={doorImage} width={248} height={196} alt="Porte" aria-hidden="true" />
          </Group>
        </Styled.LockScreenContainer>
      </Box>
    </MediaQuery>
  );
};

export default LockScreen;
