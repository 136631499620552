import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { useFetchTask, useSendTask } from '@core/http/hooks';
import { useTokenParam } from '@core/router';
import PasswordsForm from '@modules/auth/components/passwords/PasswordsForm';
import { PasswordsFormBody, ResetPasswordToken } from '@modules/auth/model';
import * as AuthService from '@modules/auth/service';
import Seo from '@shared/modules/seo/Seo';
import { renderHttpRemoteData } from '@shared/utils/render';
import * as TE from 'fp-ts/TaskEither';

const ResetPasswordPage: FC = () => {
  const navigate = useNavigate();

  const token = useTokenParam<ResetPasswordToken>();

  const [detail] = useFetchTask(AuthService.getPasswordResetDetail, token);

  const [loading, send, error] = useSendTask(
    AuthService.resetPassword,
    {
      hideError: true,
      successMessage: 'Mot de passe modifié avec succès.',
    },
    token ?? ('' as ResetPasswordToken),
  );

  const handleResetPassword = ({ password }: PasswordsFormBody) => {
    pipe(
      () => send({ password }),
      TE.chainIOK(() => () => navigate('/login', { replace: true })),
    )();
  };

  const errorMessage = pipe(
    error,
    O.map(() => 'Une erreur technique est survenue.'),
  );

  return renderHttpRemoteData(detail, () => (
    <>
      <Seo title="Mot de passe oublié" />
      <PasswordsForm type="reset-password" loading={loading} error={errorMessage} onSubmit={handleResetPassword} />
    </>
  ));
};

export default ResetPasswordPage;
