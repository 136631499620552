import styled from '@emotion/styled';

export const AuthLayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background: ${props => props.theme.colors[props.theme.primaryColor][9]};
`;

export const AuthLayoutLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 50px 20px;
  flex: 1 1 auto;

  img {
    width: 166px;
  }
`;

export const AuthLayoutContent = styled.div`
  padding: 25px;
  background: ${props => props.theme.colors.gray[0]};
  box-shadow: 0 -2px 11px 1px rgba(0, 0, 0, 0.15);
  border-radius: 25px 25px 0 0;
`;
