import React, { FC } from 'react';
import { HttpError, HttpStatusCode } from '@core/http';

import styles from './ErrorPage.styles';
import { Link } from 'react-router-dom';
import { Button, Text, Title } from '@mantine/core';
import { useStyles } from '@styles/hooks';

interface ErrorPageProps {
  error?: HttpError;
}

const ErrorPage: FC<ErrorPageProps> = ({ error = HttpError.notFound }) => {
  const { classes } = useStyles(styles, ErrorPage, undefined);

  return (
    <div className={classes.container}>
      <Title size="h3">
        {HttpStatusCode.NOT_FOUND === error.status
          ? 'La page que vous cherchez est introuvable'
          : `Une erreur ${error.status} est survenue`}
      </Title>

      <Text p="30px 0">Nous vous invitons à revenir à l'accueil</Text>

      <Button radius="xl" variant="white" size="md" component={Link} to="/">
        Revenir à l'accueil
      </Button>
    </div>
  );
};

export default ErrorPage;
