import styled from '@emotion/styled';

export const LockScreenContainer = styled.div`
  height: 100%;
  padding: 70px 35px;
  background: ${props => props.theme.colors[props.theme.primaryColor][9]};
`;

export const LockScreenContent = styled.div`
  max-width: 540px;
  margin: 0 auto;
  padding: 50px 40px 35px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.05);
  text-align: center;
`;
