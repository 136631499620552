import React, { FC, Suspense } from 'react';

import * as Styled from './MenuLayout.styles';
import { NavLink, Outlet } from 'react-router-dom';
import { DebouncedLineLoader } from '@layout/loaders/line-loader/LineLoader';
import { useAuthorization } from '@modules/auth/context';

const MenuLayout: FC = () => {
  const canAccessAlerts = useAuthorization('alerts');

  return (
    <Styled.MenuLayoutContainer>
      <Suspense fallback={<DebouncedLineLoader />}>
        <Outlet />
      </Suspense>

      <Styled.MenuContainer>
        <Styled.MenuContent $itemCount={canAccessAlerts ? 4 : 3}>
          <Styled.MenuItem>
            <NavLink to="scan" className="scan">
              Scanner <br /> une porte
            </NavLink>
          </Styled.MenuItem>

          <Styled.MenuItem>
            <NavLink to="doors" className="doors">
              Portes
            </NavLink>
          </Styled.MenuItem>

          {canAccessAlerts ? (
            <Styled.MenuItem>
              <NavLink to="alerts" className="alerts">
                Infos résident
              </NavLink>
            </Styled.MenuItem>
          ) : null}

          <Styled.MenuItem>
            <NavLink to="profile" className="profile">
              Mon profil
            </NavLink>
          </Styled.MenuItem>
        </Styled.MenuContent>
      </Styled.MenuContainer>
    </Styled.MenuLayoutContainer>
  );
};

export default MenuLayout;
