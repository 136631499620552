import { NotificationProps, showNotification as showMantineNotification } from '@mantine/notifications';

export function showNotification(props: NotificationProps) {
  showMantineNotification({
    ...props,
    styles: theme => ({
      root: {
        paddingTop: 20,
        paddingBottom: 20,
        background: theme.colors.gray[8],
        color: '#fff',
        '&:before': {
          display: 'none',
        },
      },
      icon: { display: 'none' },
      title: { color: theme.white },
      description: { color: theme.white },
      closeButton: {
        color: theme.white,
      },
    }),
  });
}
