import React, { FC, PropsWithChildren, useEffect } from 'react';
import { BrowserRouter, useLocation } from 'react-router-dom';
import RootRoutes from 'routes';
import { HelmetProvider } from 'react-helmet-async';

import { AuthContextProvider } from '@modules/auth/context';
import { GlobalStyles } from '@styles/global';
import MantineTheme from '@styles/mantine';
import { usePWA } from '@shared/hooks/pwa';
import ApiDownIndicator from '@core/http/components/ApiDownIndicator';

const ScrollRestoration: FC<PropsWithChildren> = ({ children }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return <>{children}</>;
};

const App: FC = () => {
  usePWA();

  return (
    <MantineTheme>
      <GlobalStyles />

      <ApiDownIndicator />

      <HelmetProvider>
        <BrowserRouter>
          <ScrollRestoration>
            <AuthContextProvider>
              <RootRoutes />
            </AuthContextProvider>
          </ScrollRestoration>
        </BrowserRouter>
      </HelmetProvider>
    </MantineTheme>
  );
};

export default App;
