import React, { FC } from 'react';
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import { useAuthContext } from '@modules/auth/context';
import { renderOptional } from '@shared/utils/render';

import AuthLayout from '@modules/auth/components/layout/AuthLayout';
import LoginPage from '@modules/auth/LoginPage';
import ForgotPasswordPage from '@modules/auth/ForgotPasswordPage';
import MenuLayout from '@layout/MenuLayout';
import ProfileMenuPage from '@modules/auth/ProfileMenuPage';
import ProfilePage from '@modules/auth/ProfilePage';

import ErrorPage from '@shared/components/error-page/ErrorPage';
import ActivationPage from '@modules/auth/ActivationPage';
import { safeLazy } from '@core/router';
import ResetPasswordPage from '@modules/auth/ResetPasswordPage';
import LockScreen, { LOCK_SCREEN_MAX_WIDTH } from '@shared/components/lock/LockScreen';
import { Box, MediaQuery } from '@mantine/core';
import SentryProvider from '@shared/modules/sentry/components/SentryProvider';

const ScanPage = safeLazy(() => import('@modules/scan/ScanPage'));
const DoorsListPage = safeLazy(() => import('@modules/doors/ListPage'));
const AlertsListPage = safeLazy(() => import('@modules/alerts/ListPage'));

const AuthGuard: FC = () => {
  const { profile } = useAuthContext();

  const location = useLocation();

  return renderOptional(
    profile,
    () => <Outlet />,
    () => <Navigate to="/login" replace state={{ referrer: location }} />,
  );
};

const RootRoutes: FC = () => {
  const { profile } = useAuthContext();

  return (
    <SentryProvider profile={profile}>
      <MediaQuery largerThan={LOCK_SCREEN_MAX_WIDTH + 1} styles={{ display: 'none' }}>
        <Box sx={{ height: '100%' }}>
          <Routes>
            <Route element={<AuthLayout />}>
              <Route path="login" element={<LoginPage />} />
              <Route path="password-reset/forgot" element={<ForgotPasswordPage />} />
              <Route path="password-reset/:token" element={<ResetPasswordPage />} />
              <Route path="activation/:token" element={<ActivationPage />} />
            </Route>

            <Route element={<AuthGuard />}>
              <Route index element={<Navigate to="doors" replace />} />

              <Route element={<MenuLayout />}>
                <Route path="scan" element={<ScanPage />} />
                <Route path="doors" element={<DoorsListPage />} />
                <Route path="alerts" element={<AlertsListPage />} />
                <Route path="profile" element={<ProfileMenuPage />} />
              </Route>

              <Route path="profile/update" element={<ProfilePage />} />
            </Route>

            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </Box>
      </MediaQuery>

      <LockScreen />
    </SentryProvider>
  );
};

export default RootRoutes;
