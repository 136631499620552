import React, { FC } from 'react';

import * as Styled from './ProfileMenu.styles';
import { Title } from '@mantine/core';
import { useAuthContext } from '@modules/auth/context';
import Seo from '@shared/modules/seo/Seo';
import config from '@root/config';

const ProfileMenu: FC = () => {
  const { logout } = useAuthContext();

  return (
    <Styled.ProfileMenuContainer>
      <Seo title="Mon profil" />

      <Styled.ProfileMenuHeader>
        <Title size="h3" align="center">
          Mon Profil
        </Title>
      </Styled.ProfileMenuHeader>

      <Styled.ProfileMenuContent>
        <Styled.ProfileMenuInternalItem className="user" to="/profile/update">
          Modifier mon profil
        </Styled.ProfileMenuInternalItem>

        <Styled.ProfileMenuExternalItem
          className="legal"
          href={config.VITE_CGU_URL}
          target="_blank"
          rel="noopener noreferrer"
        >
          Conditions Général d'Utilisation
        </Styled.ProfileMenuExternalItem>

        <Styled.ProfileMenuExternalItem
          className="legal"
          href={config.VITE_LEGAL_NOTICE_URL}
          target="_blank"
          rel="noopener noreferrer"
        >
          Mentions légales
        </Styled.ProfileMenuExternalItem>

        <Styled.ProfileMenuExternalItem className="phone" href={`tel:${config.VITE_CONTACT_PHONE}`}>
          Nous contacter
        </Styled.ProfileMenuExternalItem>
      </Styled.ProfileMenuContent>

      <Styled.ProfileMenuLogout onClick={logout}>Se déconnecter</Styled.ProfileMenuLogout>
    </Styled.ProfileMenuContainer>
  );
};

export default ProfileMenu;
