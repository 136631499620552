import React, { FC } from 'react';
import { Global } from '@mantine/core';

const GlobalStyles: FC = () => {
  return (
    <Global
      styles={theme => ({
        'html, body, #root': {
          background: theme.colors.gray[0],
          height: '100%',
          width: '100%'
        }
      })}
    />
  );
};

export { GlobalStyles };
