import styled from '@emotion/styled';

import arrowIcon from '@assets/icons/arrow-white.svg';

export const SimplePageHeader = styled.button`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 15px 25px 15px 63px;
  background: url(${arrowIcon}) no-repeat left 26px center ${props => props.theme.colors.teal[9]};
  border: none;
  outline: none;
  color: #fff;
  font: inherit;
  text-align: left;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
  z-index: 100;
`;

export const SimplePageContent = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding: 75px 25px 25px 25px;
`;
