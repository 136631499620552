import styled from '@emotion/styled';

import scanIcon from '@assets/icons/menu/scan.svg';
import scanActiveIcon from '@assets/icons/menu/scan-active.svg';
import alertsIcon from '@assets/icons/menu/alerts.svg';
import alertsActiveIcon from '@assets/icons/menu/alerts-active.svg';
import doorsIcon from '@assets/icons/menu/doors.svg';
import doorsActiveIcon from '@assets/icons/menu/doors-active.svg';
import profileIcon from '@assets/icons/menu/profile.svg';
import profileActiveIcon from '@assets/icons/menu/profile-active.svg';

export const MENU_HEIGHT = 80;

export const MenuLayoutContainer = styled.div`
  padding-bottom: 96px;
`;

export const MenuContainer = styled.nav`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: ${MENU_HEIGHT}px;
  box-shadow: 0 -2px 11px 1px rgba(0, 0, 0, 0.09);
  background-color: #fff;
  border-radius: 20px 20px 0 0;
  overflow: hidden;
  z-index: 100;
`;

export const MenuContent = styled.ul<{ $itemCount: number }>`
  display: grid;
  grid-template-columns: repeat(${props => props.$itemCount}, minmax(0, 1fr));
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  list-style: none;
`;

export const MenuItem = styled.li`
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    padding-top: 28px;
    font-size: 10px;
    font-weight: 600;
    line-height: 1.1;
    text-decoration: none;
    text-align: center;
    background: no-repeat top 15px center;
    color: ${props => props.theme.colors.dark[2]};
    transition: all 0.15s ease-in-out;

    &.active {
      color: ${props => props.theme.colors.dark[6]};
    }

    &.scan {
      background-image: url(${scanIcon});

      &.active {
        background-image: url(${scanActiveIcon});
      }
    }

    &.doors {
      background-image: url(${doorsIcon});

      &.active {
        background-image: url(${doorsActiveIcon});
      }
    }

    &.alerts {
      background-image: url(${alertsIcon});

      &.active {
        background-image: url(${alertsActiveIcon});
      }
    }

    &.profile {
      background-image: url(${profileIcon});

      &.active {
        background-image: url(${profileActiveIcon});
      }
    }
  }
`;
