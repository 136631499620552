import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

import arrowIcon from '@assets/icons/arrow.svg';
import userIcon from '@assets/icons/profile/user.svg';
import legalIcon from '@assets/icons/profile/legal.svg';
import phoneIcon from '@assets/icons/profile/phone.svg';

import { css, Theme } from '@emotion/react';

export const ProfileMenuContainer = styled.div``;

export const ProfileMenuHeader = styled.div`
  padding: 28px 25px;
  background: #fff;
`;

export const ProfileMenuContent = styled.div`
  padding: 20px;
`;

const profileMenuItemCss = ({ theme }: { theme: Theme }) => css`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  min-height: 77px;
  padding: 18px 40px 18px 83px;
  background: url(${arrowIcon}) no-repeat right 14px center #fff;
  border-radius: 8px;
  font-size: 14px;
  font-weight: bold;
  color: ${theme.colors.dark[9]};
  text-decoration: none;

  &:before {
    display: block;
    position: absolute;
    left: 21px;
    top: 50%;
    content: '';
    width: 41px;
    height: 41px;
    background: ${theme.colors.teal[0]} no-repeat center center;
    border-radius: 100%;
    transform: translateY(-50%);
  }

  &.user:before {
    background-image: url(${userIcon});
  }

  &.legal:before {
    background-image: url(${legalIcon});
  }

  &.phone:before {
    background-image: url(${phoneIcon});
  }
`;

export const ProfileMenuInternalItem = styled(Link)`
  ${profileMenuItemCss};
`;

export const ProfileMenuExternalItem = styled.a`
  ${profileMenuItemCss};
`;

export const ProfileMenuLogout = styled.button`
  position: fixed;
  left: 50%;
  bottom: 100px;
  background: none;
  font: inherit;
  font-size: 14px;
  font-weight: 600;
  color: #000;
  text-decoration: underline;
  transform: translateX(-50%);
  border: none;
  outline: none;
  cursor: pointer;
`;
