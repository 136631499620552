import { useEffect } from 'react';
import { registerSW } from 'virtual:pwa-register';

export function usePWA() {
  useEffect(() => {
    let registration: ServiceWorkerRegistration | undefined = undefined;

    registerSW({
      onRegisteredSW(url, r) {
        registration = r;
      },
    });

    const handleWindowFocus = () => registration?.update();

    window.addEventListener('focus', handleWindowFocus);

    return () => {
      window.removeEventListener('focus', handleWindowFocus);
    };
  }, []);
}
