import React, { FC } from 'react';
import SimplePage from '@layout/page/simple/SimplePage';
import { useAuthContext } from '@modules/auth/context';
import { renderOptional } from '@shared/utils/render';
import ProfileForm from '@modules/auth/components/profile/form/ProfileForm';
import { UpdateProfileParams } from '@modules/auth/model';
import { useSendTask } from '@core/http/hooks';

import * as AuthService from './service';
import { pipe } from 'fp-ts/function';
import * as TE from 'fp-ts/TaskEither';

const ProfilePage: FC = () => {
  const { profile, updateProfile } = useAuthContext();

  const [loading, sendUpdateProfile] = useSendTask(AuthService.updateProfile, { successMessage: 'Profil modifié.' });
  const [, sendForgotPassword] = useSendTask(AuthService.forgotPassword, {
    successMessage: 'Message bien envoyé, consultez votre boite e-mail.',
  });

  const handleSubmit = (params: UpdateProfileParams) => {
    pipe(
      () => sendUpdateProfile(params),
      TE.chainIOK(profile => () => updateProfile(profile)),
    )();
  };

  const handleForgotPassword = (email: string) => sendForgotPassword({ email });

  return (
    <SimplePage title="Mon profil">
      {renderOptional(profile, profile => (
        <ProfileForm
          profile={profile}
          loading={loading}
          onSubmit={handleSubmit}
          onForgotPassword={handleForgotPassword}
        />
      ))}
    </SimplePage>
  );
};

export default ProfilePage;
