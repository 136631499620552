import React, { FC } from 'react';

import * as Styled from './AuthLayout.styles';

import logo from '@assets/logos/logo-white.svg';
import { Outlet } from 'react-router-dom';

const AuthLayout: FC = () => {
  return (
    <Styled.AuthLayoutContainer>
      <Styled.AuthLayoutLogoWrapper>
        <img src={logo} alt="Logo Hapiix" />
      </Styled.AuthLayoutLogoWrapper>

      <Styled.AuthLayoutContent>
        <Outlet />
      </Styled.AuthLayoutContent>
    </Styled.AuthLayoutContainer>
  );
};

export default AuthLayout;
