import React, { FC } from 'react';
import { Profile, UpdateProfileParams, updateProfileSchema } from '@modules/auth/model';
import { Anchor, Button, TextInput } from '@mantine/core';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import * as Styled from './ProfileForm.styles';

interface ProfileFormProps {
  profile: Profile;
  loading: boolean;
  onSubmit: (params: UpdateProfileParams) => void;
  onForgotPassword: (email: string) => void;
}

const ProfileForm: FC<ProfileFormProps> = ({ profile, loading, onSubmit, onForgotPassword }) => {
  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<UpdateProfileParams>({
    resolver: zodResolver(updateProfileSchema),
    defaultValues: {
      firstName: profile.firstName ?? '',
      lastName: profile.lastName ?? '',
    },
  });

  const handleForgotPassword = () => onForgotPassword(profile.email);

  return (
    <Styled.ProfileFormContainer onSubmit={handleSubmit(onSubmit)} noValidate>
      <Styled.ProfileFormContent>
        <TextInput
          id="firstName"
          label="Prénom"
          required
          placeholder="Prénom"
          error={!!errors.firstName}
          {...register('firstName')}
        />

        <TextInput
          mt="md"
          id="lastName"
          label="Nom"
          required
          placeholder="Nom"
          error={!!errors.lastName}
          {...register('lastName')}
        />

        <TextInput mt="md" id="email" type="email" label="E-mail" disabled placeholder="E-mail" value={profile.email} />

        <Anchor
          component="button"
          mt={40}
          p="5px 0"
          type="button"
          underline
          color="dark.9"
          weight={600}
          size="sm"
          onClick={handleForgotPassword}
        >
          Changer de mot de passe
        </Anchor>
      </Styled.ProfileFormContent>

      <Styled.ProfileFormActions>
        <Button type="submit" size="xl" radius="xl" loading={loading}>
          Enregistrer
        </Button>
      </Styled.ProfileFormActions>
    </Styled.ProfileFormContainer>
  );
};

export default ProfileForm;
