import React, { FC } from 'react';

import * as O from 'fp-ts/Option';

import { HttpError, HttpStatusCode } from '@core/http';
import { ForgotPasswordParams, forgotPasswordSchema } from '@modules/auth/model';
import { renderOptional } from '@shared/utils/render';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { Alert, Anchor, Button, Group, Text, TextInput } from '@mantine/core';
import { Link } from 'react-router-dom';

interface ForgotPasswordFormProps {
  loading: boolean;
  error: O.Option<HttpError>;
  onSubmit: (params: ForgotPasswordParams) => void;
}

const ForgotPasswordForm: FC<ForgotPasswordFormProps> = ({ loading, error, onSubmit }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<ForgotPasswordParams>({
    resolver: zodResolver(forgotPasswordSchema),
    defaultValues: {
      email: '',
    },
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      {renderOptional(error, error => (
        <Alert color="red" mb={10}>
          <Text color="red" size="sm" weight={600} align="center">
            {HttpStatusCode.NOT_FOUND === error.status
              ? "L'email n'est rattaché à aucun compte."
              : 'Une erreur technique est survenue.'}
          </Text>
        </Alert>
      ))}

      <Text size="sm">
        Après avoir saisi votre e-mail de connexion, nous vous enverrons un lien afin de choisir un nouveau mot de passe.
      </Text>

      <TextInput
        pt="sm"
        type="email"
        id="email"
        label="Adresse e-mail"
        placeholder="Adresse e-mail"
        required={!!errors.email}
        {...register('email')}
      />

      <Group mt={45} position="center">
        <Button type="submit" loading={loading} disabled={loading} radius={32} size="lg">
          Recevoir un lien par e-mail
        </Button>
      </Group>

      <Group mt={15} position="center">
        <Anchor
          component={Link}
          to="/login"
          size="sm"
          weight="600"
          color="dark"
          sx={{ textDecoration: 'underline' }}>
          Retour
        </Anchor>
      </Group>
    </form>
  );
};

export default ForgotPasswordForm;
