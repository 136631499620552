import axios from 'axios';

import { v4 as uuid } from 'uuid';
import { stringifyQueries } from '@shared/utils/queries';
import { TRANSACTION_ID_HEADER_KEY } from '@core/http/model';
import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import config from '@root/config';

const baseURL = pipe(
  O.tryCatch(() => new URL(config.VITE_API_PREFIX, config.VITE_API_URL).toString()),
  O.filter(() => import.meta.env.PROD),
  O.getOrElse(() => config.VITE_API_PREFIX),
);

export const defaultAxiosInstance = axios.create({
  paramsSerializer: { serialize: stringifyQueries },
  withCredentials: true,
  baseURL,
});

defaultAxiosInstance.interceptors.request.use(config => ({
  ...config,
  headers: {
    ...config.headers,
    [TRANSACTION_ID_HEADER_KEY]: uuid(),
  },
}));
