import { MantineProvider } from '@mantine/core';
import { NotificationsProvider } from '@mantine/notifications';
import React, { FC, PropsWithChildren } from 'react';
import 'dayjs/locale/fr';
import { Calendar } from 'tabler-icons-react';
const MantineTheme: FC<PropsWithChildren> = ({ children }) => {
  return (
    <MantineProvider
      theme={{
        fontFamily: "'Montserrat', sans-serif",
        primaryColor: 'teal',
        primaryShade: 9,
        colors: {
          teal: [
            '#e6fcf5',
            '#c3fae8',
            '#96f2d7',
            '#63e6be',
            '#38d9a9',
            '#20c997',
            '#12b886',
            '#0ca678',
            '#099268',
            '#167e59',
          ],
          gray: [
            '#f8f7f5',
            '#f8f3e8',
            '#E9ECEF',
            '#DEE2E6',
            '#CED4DA',
            '#ADB5BD',
            '#868E96',
            '#495057',
            '#343A40',
            '#212529',
          ],
        },
        headings: {
          fontWeight: 'bold',
          fontFamily: "'Montserrat', sans-serif",
        },
        components: {
          DatePicker: {
            defaultProps: {
              locale: 'fr',
              inputFormat: 'DD/MM/YYYY',
              icon: <Calendar size={20} />,
              size: 'md',
              radius: 'md',
            },
          },
          Textarea: {
            defaultProps: {
              radius: 'md',
              size: 'md',
            },
          },
          PasswordInput: {
            defaultProps: {
              radius: 'md',
              size: 'md',
            },
          },
          TextInput: {
            defaultProps: {
              radius: 'md',
              size: 'md',
            },
          },
          InputWrapper: {
            defaultProps: {
              size: 'md',
            },
            styles: {
              label: {
                fontWeight: 600,
                paddingBottom: 5,
              },
            },
          },
          Input: {
            styles: {
              input: {
                '&:disabled': {
                  background: '#f8f9fa',
                  color: '#000',
                  opacity: 0.7,
                },
              },
            },
          },
          MultiSelect: {
            styles: {
              disabled: {
                background: '#f8f9fa',
                opacity: 0.8,
                color: '#909296',
              },
            },
          },
          Modal: {
            defaultProps: {
              overlayColor: 'grey',
              overlayOpacity: 0.5,
              overlayBlur: 1,
            },
          },
        },
      }}
      withNormalizeCSS
      withGlobalStyles
    >
      <NotificationsProvider position="bottom-right">{children}</NotificationsProvider>
    </MantineProvider>
  );
};

export default MantineTheme;
