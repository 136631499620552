import React, { FC } from 'react';
import { useSendTask } from '@core/http/hooks';
import * as AuthService from '@modules/auth/service';
import { ForgotPasswordParams } from '@modules/auth/model';
import { useNavigate } from 'react-router-dom';
import ForgotPasswordForm from '@modules/auth/components/passwords/forgot/ForgotPasswordForm';
import Seo from '@shared/modules/seo/Seo';
import { pipe } from 'fp-ts/function';
import * as TE from 'fp-ts/TaskEither';
import { Title } from '@mantine/core';

const ForgotPasswordPage: FC = () => {
  const navigate = useNavigate();

  const [loading, send, error] = useSendTask(AuthService.forgotPassword, {
    hideError: true,
    successMessage: 'Un e-mail vous a été envoyé avec la procédure à suivre pour choisir un nouveau mot de passe.',
  });

  const handleForgotPassword = (params: ForgotPasswordParams) => {
    pipe(
      () => send(params),
      TE.chainIOK(() => () => {
        navigate('/login', { replace: true });
      }),
    )();
  };

  return (
    <>
      <Seo title="Mot de passe oublié" />

      <Title size="h3" mb="md">
        Mot de passe oublié
      </Title>

      <ForgotPasswordForm loading={loading} error={error} onSubmit={handleForgotPassword} />
    </>
  );
};

export default ForgotPasswordPage;
