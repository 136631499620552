import styled from '@emotion/styled';

export const ProfileFormContainer = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 100%;
`;

export const ProfileFormContent = styled.div`
  height: 100%;
  flex: 1 1 auto;
`;

export const ProfileFormActions = styled.div`
  padding: 20px 0 0;
  text-align: center;

  button {
    height: 43px;
  }
`;
